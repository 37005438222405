import React from 'react';

function Footer() {

    return (
        <footer>
            <h2>Thank you for visiting my page!</h2>
        </footer>
    )
}

export default Footer;